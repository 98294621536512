import KyleHughes from "../assests/Kyle Hughes.jpg";
import BlakeBloor from "../assests/Blake Bloor.jpg";
import DylanRobertson from "../assests/Dylan Robertson.jpg";

export default function Testimonials() {
  return (
    <section className="bg-black pb-24 pt-8 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h1 className="mx-auto pb-24 pt-16 text-center text-3xl font-bold text-white sm:text-4xl">
          From the Team
        </h1>
        <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
            {/* Title */}

            <figure className="mt-10 flex flex-auto flex-col justify-between">
              <blockquote className="text-lg leading-8 text-white">
                <p>
                  “In today's fast-paced world, small businesses need to stay
                  agile and connected. Our mission is to remove the barriers of
                  tech setup and maintenance, giving back valuable time to our
                  clients so they can focus on what truly matters – their
                  business and customers.”
                </p>
              </blockquote>
              <figcaption className="mt-10 flex items-center gap-x-6">
                <img
                  src={KyleHughes}
                  alt="Kyle Hughes"
                  className="h-12 w-12 flex-none rounded-full bg-none object-cover"
                />
                <div className="text-base">
                  <div className="font-semibold text-white">Kyle Huges</div>
                  <div className="mt-1 text-gray-400">
                    Managing Director, AtlasTech Solutions
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
            {/* Placeholder for company logo */}
            <figure className="mt-10 flex flex-auto flex-col justify-between">
              <blockquote className="text-lg leading-8 text-white">
                <p>
                  “We understand the pain points of not having a dedicated IT
                  team. That's why we provide a service that's like having your
                  own tech department, but better. It's about offering peace of
                  mind with a service that's as reliable as it is
                  user-friendly.”
                </p>
              </blockquote>
              <figcaption className="mt-10 flex items-center gap-x-6">
                {/* Founder image */}
                <img
                  src={BlakeBloor}
                  alt="Blake Bloor"
                  className="h-12 w-12 flex-none rounded-full bg-none object-cover"
                />
                <div className="text-base">
                  <div className="font-semibold text-white">Blake Bloor</div>
                  <div className="mt-1 text-gray-400">
                    Operating Officer, AtlasTech Solutions
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
