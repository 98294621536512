import {
  DevicePhoneMobileIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { PRODUCT } from "../PageRoutes";

export default function ContentSection() {
  return (
    <div className="relative bg-black text-white">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="relative lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              className="absolute inset-0 h-full w-full rounded-lg bg-gray-50 object-cover shadow-lg"
              src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90oy1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
              alt=""
            />
            <div className="absolute inset-0 h-full w-full border-8 border-black blur-md" />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            {/* This section can introduce AtlasTech's vision */}
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
              Simplify Your Business Connectivity
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-300">
              With AtlasTech Solutions, equip your team with the technology they
              need without the complexity or the cost. Our managed device leases
              bring flexibility and accessibility to your fingertips.
            </p>
            {/* Feature points with icons */}
            <ul role="list" className="mt-8 space-y-8 text-gray-300">
              <li className="flex gap-x-3">
                <DevicePhoneMobileIcon
                  className="mt-1 h-5 w-5 flex-none text-atlas-yellow"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-100">
                    Comprehensive Device Management.
                  </strong>{" "}
                  Benefit from a range of high-quality devices, fully managed to
                  meet your business needs.
                </span>
              </li>
              <li className="flex gap-x-3">
                <ShieldCheckIcon
                  className="mt-1 h-5 w-5 flex-none text-atlas-yellow"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-100">
                    All-Inclusive Coverage.
                  </strong>{" "}
                  Every lease comes with unlimited accidental damage coverage
                  and a comprehensive warranty for peace of mind.
                </span>
              </li>
              <li className="flex gap-x-3">
                <CurrencyDollarIcon
                  className="mt-1 h-5 w-5 flex-none text-atlas-yellow"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-100">
                    Invest in Your Assets.
                  </strong>{" "}
                  Part of your lease payments goes toward the buyout price,
                  turning your leases into investments and giving you the option
                  to own.
                </span>
              </li>
            </ul>
            {/* Additional persuasive copy */}
            <p className="mt-8">
              Say goodbye to long-term contracts and hello to scalability. Our
              flexible terms mean you can adjust your tech needs as your
              business evolves. AtlasTech Solutions is here to make technology
              work for you, not against you.
            </p>
            {/* Closing statement */}
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-100">
              Ready to Upgrade Your Tech?
            </h2>
            <p className="mt-6">
              Get in touch with us today and discover how our managed device
              leases can streamline your business operations. No more tech
              headaches, no more downtime - just pure productivity.
            </p>
            <div className="mt-10 flex items-center justify-center">
              <Link
                to={PRODUCT}
                className={`rounded-md bg-atlas-yellow px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm
                 hover:bg-atlas-yellow-dark focus-visible:outline focus-visible:outline-2
                 focus-visible:outline-offset-2 focus-visible:outline-white`}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
