import { Typography } from "@material-tailwind/react";
import AtlasSolutionsLogo from "../assests/Atlas_Solutions.png";
import { Link } from "react-router-dom";
import { ABOUT_US, CONTACT_US, PRODUCT } from "../PageRoutes";

const LINKS = [
  {
    title: "Legal",
    items: [
      { link: "Privacy Policy", destination: "/privacy-policy" },
      { link: "Sales and Returns Policy", destination: "/sales-policy" },
      { link: "Refund Policy", destination: "/refund-policy" },
      { link: "Terms of Use", destination: "/terms-of-use" },
    ],
  },
  {
    title: "Links",
    items: [
      { link: "Home", destination: "/" },
      { link: "Services", destination: PRODUCT },
      { link: "About us", destination: ABOUT_US },
      { link: "Contact Us", destination: CONTACT_US },
    ],
  },
];

const social = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/atscanberra",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Twitter",
    href: "https://twitter.com/AtlasTechS62016",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/atlastechsolutions",
    icon: (props) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-linkedin"
        viewBox="0 0 24 24"
      >
        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
      </svg>
    ),
  },
];

export default function Footer({ className }) {
  return (
    <footer className={`relative w-full bg-gray-900 py-8 ${className}`}>
      <div className="mx-auto w-full max-w-7xl px-8">
        <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-2 xl:mx-auto">
          <Typography variant="h5" className="mb-6">
            <img className="w-1/2" src={AtlasSolutionsLogo} alt="logo" />
          </Typography>
          <div className="grid grid-cols-2 justify-between gap-32 md:mx-auto">
            {LINKS.map(({ title, items }) => (
              <ul key={title}>
                <Typography
                  color="white"
                  className="mb-3 font-medium opacity-40"
                >
                  {title}
                </Typography>
                {items.map((item) => (
                  <li key={item.link}>
                    <Link
                      to={item.destination}
                      className="hover:white py-1.5 font-normal text-white transition-colors"
                    >
                      {item.link}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-white py-4 md:flex-row md:justify-between"></div>
        <div className="mb-32 flex justify-center space-x-6">
          {social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-400"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}
