import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="bg-gray-100 px-4 pb-8 pt-32 md:pt-48">
        <div className="mx-auto max-w-4xl rounded-lg bg-white p-6 shadow">
          <h1 className="mb-4 text-2xl font-bold text-gray-700">
            Privacy Policy
          </h1>
          <p className="mb-2 text-gray-600">Last updated: [11/11/2023]</p>
          <p className="mb-4 text-gray-600">
            At AtlasTech, we recognise the importance of protecting the privacy
            of the personal information we collect from our customers and
            prospective customers. We are committed to and bound by the
            Australian Privacy Principles under the Privacy Act 1988 Cth (as
            amended).
          </p>
          <p className="mb-4 text-gray-600">
            This statement discloses what information we collect and how we use,
            disclose and store your personal information, as well as how you can
            contact us and get access to your information. Our privacy policy
            applies to all your dealings with us whether the dealing is through
            your personal visits to our shop fronts or offices, by telephone,
            mail, or our internet web-site.
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            What personal information do we collect, and how do we get it?
          </h2>
          <p className="mb-4 text-gray-600">
            We collect your personal information from our hire agreements, our
            account application forms, or what you tell us or show us when you
            telephone us, visit our shop fronts or offices, or browse on our
            website. We also collect personal information from credit reporting
            agencies and from searches we conduct, including the PPSR Register.
            When you are making enquiries about our products and services, we
            will usually ask you for your name, your contact details including
            your phone number, address, and email address. This helps us to
            contact you to answer your enquiries. When you hire any devices from
            us, we will request that you supply us with some or all of the
            following:
          </p>
          <ul className="mb-4 list-disc pl-5 text-gray-600">
            <li>
              Your name, street address, email address, telephone, mobile and
              facsimile numbers, your company or business name to identify you,
              and for us to contact you.
            </li>
            <li>
              Details of your Drivers Licence, or a photocopy or digital image
              of it, so that we can confirm your identity and your residence.
            </li>
            <li>
              Your credit card details for the purpose of paying for your
              subscriptions and purchases from us are stored by STRIPE PAYMENTS
              AUSTRALIA PTY LTD, you can find their Data Handling Policy here.
            </li>
          </ul>
          <p className="mb-4 text-gray-600">
            For ABN customers, if you apply for a credit account with us, we may
            ask for the above information, as well as:
          </p>
          <ul className="mb-4 list-disc pl-5 text-gray-600">
            <li>Your bank account details.</li>
            <li>Trade references to allow us to assess your background; and</li>
            <li>
              If you are a company, name and address details of all directors of
              the company.
            </li>
            <li>
              We do not seek to collect any sensitive information background,
              political beliefs etc.
            </li>
          </ul>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            What do we use this personal information for?
          </h2>
          <p className="mb-4 text-gray-600">
            All information sought is required for our business purposes, which
            includes:
          </p>
          <ul className="mb-4 list-disc pl-5 text-gray-600">
            <li>Responding to your enquiries or requests</li>
            <li>
              To provide you with the devices you wish to hire, and other
              products and services
            </li>
            <li>
              To help us to identify other products and services that might be
              beneficial to you, and to inform you about them and items of
              interest
            </li>
            <li>
              To evaluate the needs of our customers and to develop new products
            </li>
            <li>Internal accounting and administration</li>
            <li>To protect you and us from fraud</li>
            <li>Direct Marketing</li>
            <li>Setting up an account</li>
          </ul>
          <p className="mb-4 text-gray-600">
            When you visit our website, we may collect the above information
            from you if you provide it to us. We may also collect logging
            information about web site activities (e.g. date and time of visits,
            the number of pages viewed, your operating system etc.) using
            “cookies”, as commonly used by most other web sites.{" "}
          </p>

          <p className="mb-4 text-gray-600">
            A cookie is a packet of information that allows the hosting computer
            to identify and interact with you. The cookies do not identify any
            personal information but may provide us with statistical information
            that we can use to analyse our services. If your computer does not
            allow the placement of cookies, your navigation on our website may
            be restricted.
          </p>
          <p className="mb-4 text-gray-600">
            Your personal information may be shared with any members of the
            AtlasTech Solutions Group of Companies.
          </p>
          <p className="mb-4 text-gray-600">
            We assume we have your consent to use your personal information for
            the above purposes, in particular, for the purposes of sending
            information to you about our products and services, unless you
            advise us not to. If you do not want us to contact you or send you
            information, please let us know.
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Opting Out
          </h2>
          <p className="mb-4 text-gray-600">
            You may opt out of receiving promotional materials from us, by
            emailing admin@atscanberra.com.au
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Disclosure of your personal information to parties outside our
            organisation
          </h2>
          <p className="mb-4 text-gray-600">
            As a general principle, AtlasTech will not provide any unrelated
            party (including any overseas recipients) with any personal
            information it has collected about you. Exceptions may occur where:
          </p>
          <ul className="mb-4 list-disc pl-5 text-gray-600">
            <li>AtlasTech believes in good faith that you have consented;</li>
            <li>
              We use external service providers e.g. mailing houses to mail
              promotional material to you. The information will be provided to
              our external service providers on a confidential basis for the
              purposes of our business only, and we will take reasonable steps
              to ensure that it is dealt with according to the same high
              standards as we use;
            </li>
            <li>
              A law enforcement agency, or other government agency, is
              exercising its legal authority and has asked AtlasTech to provide
              access to that information;
            </li>
            <li>
              You are in default of a hire contract or other contractual
              commitment to us, in which case we may need to release your
              details and the relevant history of your transactions with us to
              our legal advisers, debt collection agencies, credit reference
              bodies, or industry associations.
            </li>
            <li>
              We are asked to share that information with a credit bureau or
              reporting agency.
            </li>
            <li>
              AtlasTech also uses Google Analytics to collect and process
              information. For further details please view "How Google uses data
              when you use our partners' sites or apps" (located at [link]).
            </li>
            <li>
              Other than the purposes above, we will not disclose your
              information unless with your consent, or as required or authorised
              by law.
            </li>
          </ul>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Protecting your personal information
          </h2>
          <p className="mb-4 text-gray-600">
            We store information in different ways, including hard copies and
            electronic form. We take all reasonable care to ensure that the
            personal information about you is protected from loss, misuse or
            alteration.
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Gaining access to your personal information
          </h2>
          <p className="mb-4 text-gray-600">
            You have a right to access the personal information we store about
            you. If you wish to access your information, please write to us (our
            contact details are at the end of this statement). Subject to
            verification of your identity, we will provide you with access to
            any information we are holding about you as soon as practicable. We
            may charge you the reasonable costs of processing your request.
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Keeping your personal information accurate and up to date
          </h2>
          <p className="mb-4 text-gray-600">
            If you find that the information we hold about you is inaccurate,
            incomplete, or out of date, please let us know, and we will take
            reasonable steps to correct it.
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Dealing with us
          </h2>
          <p className="mb-4 text-gray-600">
            You can refuse to provide us with your personal information if you
            are making general inquiries about our products and services.
            However, we may not be able to answer your requests in some cases,
            or{" "}
          </p>
          <p className="mb-4 text-gray-600">
            conduct business with you, if you refuse to disclose the personal
            information that we require.
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Contacting us
          </h2>
          <p className="mb-4 text-gray-600">
            To obtain more information about our privacy policy and how we
            manage your personal information, please call (02) 8880 2313 to
            speak to our Customer Service Representative, or you may write to us
            at 5/1 Moore St, Canberra ACT 2601 or email
            admin@atscanberra.com.au. If you have any complaint about the effect
            of our Privacy Policy on you, you may write directly for the
            personal attention of our Managing Director, at the same address.
            Your complaint will then be considered and a reply will be made to
            you with an appropriate response confirming any further action to be
            taken. If you are still not satisfied with the outcome of your
            complaint, you may refer your complaint to the Office of the
            Australian Information Commissioner.
          </p>
          <h2 className="mb-3 mt-6 text-xl font-semibold text-gray-700">
            Disclaimer:
          </h2>
          <p className="mb-4 text-gray-600">
            Protech Rentals is a subsidiary trading under Australian Registered
            Company AtlasTech Solutions ABN: 38 668 691 441
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
