import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const TermsOfUse = () => {
  return (
    <>
      <Header />
      <div className="bg-gray-100 px-4 pb-8 pt-32 md:pt-48">
        <div className="mx-auto max-w-4xl rounded-lg bg-white p-6 shadow">
          <p className="text-xl font-bold">Last updated: [24/11/2023]</p>
          <h1 className="mt-4 text-3xl font-bold">
            AtlasTech Solutions Website Terms of Use
          </h1>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Legal Information & Notices
            </h2>
            <p className="mt-2">Ownership of Site; Agreement to Terms of Use</p>
            <p className="mt-2">
              These Terms and Conditions of Use (the "Terms of Use") apply to
              the AtlasTech Solutions website located at
              AtlasTechSolutions.com.au, and all associated sites linked to
              AtlasTechSolutions.com.au by AtlasTech Solutions, its subsidiaries
              and affiliates, including AtlasTech Solutions sites around the
              world (collectively, the "Site"). The Site is the property of
              AtlasTech Solutions Pty Ltd. ("AtlasTech Solutions") and its
              licensors. BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF
              YOU DO NOT AGREE, DO NOT USE THE SITE.
            </p>
            <p className="mt-2">
              AtlasTech Solutions reserves the right, at its sole discretion, to
              change, modify, add or remove portions of these Terms of Use, at
              any time. It is your responsibility to check these Terms of Use
              periodically for changes. Your continued use of the Site following
              the posting of changes will mean that you accept and agree to the
              changes. As long as you comply with these Terms of Use, AtlasTech
              Solutions grants you a personal, non-exclusive, non-transferable,
              limited privilege to enter and use the Site.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Content</h2>
            <p className="mt-2">
              All text, graphics, user interfaces, visual interfaces,
              photographs, trademarks, logos, sounds, music, artwork and
              computer code (collectively, "Content"), including but not limited
              to the design, structure, selection, coordination, expression,
              "look and feel" and arrangement of such Content, contained on the
              Site is owned, controlled or licensed by or to AtlasTech
              Solutions.
            </p>
            <p className="mt-2">
              Except as expressly provided in these Terms of Use, no part of the
              Site and no Content may be copied, reproduced, republished,
              uploaded, posted, publicly displayed, encoded, translated,
              transmitted or distributed in any way (including "mirroring") to
              any other computer, server, Web site or other medium for
              publication or distribution or for any commercial enterprise,
              without AtlasTech Solutions’s express prior written consent.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Your Use of the Site</h2>
            <p className="mt-2">
              You may not use any "deep-link", "page-scrape", "robot", "spider"
              or other automatic device, program, algorithm or methodology, or
              any similar or equivalent manual process, to access, acquire, copy
              or monitor any portion of the Site or any Content, or in any way
              reproduce or circumvent the navigational structure or presentation
              of the Site or any Content, to obtain or attempt to obtain any
              materials, documents or information through any means not
              purposely made available through the Site. AtlasTech Solutions
              reserves the right to bar any such activity.
            </p>
            <p className="mt-2">
              You may not attempt to gain unauthorized access to any portion or
              feature of the Site, or any other systems or networks connected to
              the Site or to any AtlasTech Solutions server, or to any of the
              services offered on or through the Site, by hacking, password
              "mining" or any other illegitimate means.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Purchases; Other Terms and Conditions
            </h2>
            <p className="mt-2">
              Additional terms and conditions may apply to purchases of goods or
              services and to specific portions or features of the Site,
              including contests, promotions or other similar features, all of
              which terms are made a part of these Terms of Use by this
              reference. You agree to abide by such other terms and conditions,
              including where applicable representing that you are of sufficient
              legal age to use or participate in such service or feature. If
              there is a conflict between these Terms of Use and the terms
              posted for or applicable to a specific portion of the Site or for
              any service offered on or through the Site, the latter terms shall
              control with respect to your use of that portion of the Site or
              the specific service.
            </p>
            <p className="mt-2">
              AtlasTech Solutions’s obligations, if any, with regard to its
              products and services are governed solely by the agreements
              pursuant to which they are provided, and nothing on this Site
              should be construed to alter such agreements.
            </p>
            <p className="mt-2">
              AtlasTech Solutions may make changes to any products or services
              offered on the Site, or to the applicable prices for any such
              products or services, at any time, without notice. The materials
              on the Site with respect to products and services may be out of
              date, and AtlasTech Solutions makes no commitment to update the
              materials on the Site with respect to such products and services.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Accounts, Passwords and Security
            </h2>
            <p className="mt-2">
              Certain features or services offered on or through the Site may
              require you to open an account (including setting up an AtlasTech
              Solutions account and password). You are entirely responsible for
              maintaining the confidentiality of the information you hold for
              your account, including your password, and for any and all
              activity that occurs under your account as a result of your
              failing to keep this information secure and confidential. You
              agree to notify AtlasTech Solutions immediately of any
              unauthorized use of your account or password, or any other breach
              of security. You may be held liable for losses incurred by
              AtlasTech Solutions or any other user of or visitor to the Site
              due to someone else using your AtlasTech Solutions password or
              account as a result of your failing to keep your account
              information secure and confidential.
            </p>
            <p className="mt-2">
              You may not use anyone else’s AtlasTech Solutions password or
              account at any time without the express permission and consent of
              the holder of that AtlasTech Solutions password or account.
              AtlasTech Solutions cannot and will not be liable for any loss or
              damage arising from your failure to comply with these obligations.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Privacy</h2>
            <p className="mt-2">
              AtlasTech Solutions’s Privacy Policy applies to use of this Site,
              and its terms are made a part of these Terms of Use by this
              reference.{" "}
              <a
                href="/privacy-policy"
                className="text-blue-500 hover:underline"
              >
                View AtlasTech Solutions Privacy Policy here
              </a>
              . Additionally, by using the Site, you acknowledge and agree that
              Internet transmissions are never completely private or secure. You
              understand that any message or information you send to the Site
              may be read or intercepted by others, even if there is a special
              notice that a particular transmission (for example, credit card
              information) is encrypted.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Links to Other Sites and to the AtlasTech Solutions Site
            </h2>
            <p className="mt-2">
              This Site may contain links to other independent third-party Web
              sites ("Linked Sites"). These Linked Sites are provided solely as
              a convenience to our visitors. Such Linked Sites are not under
              AtlasTech Solutions’s control, and AtlasTech Solutions is not
              responsible for and does not endorse the content of such Linked
              Sites, including any information or materials contained on such
              Linked Sites. You will need to make your own independent judgement
              regarding your interaction with these Linked Sites.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Disclaimers</h2>
            <p className="mt-2">
              AtlasTech Solutions DOES NOT PROMISE THAT THE SITE OR ANY CONTENT,
              SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR
              UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR
              USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS
              CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. ALL
              INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT
              NOTICE. AtlasTech Solutions CANNOT ENSURE THAT ANY FILES OR OTHER
              DATA YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR
              CONTAMINATION OR DESTRUCTIVE FEATURES. AtlasTech Solutions
              DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY
              WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE. AtlasTech Solutions DISCLAIMS
              ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY
              THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE
              SITE AND/OR ANY AtlasTech Solutions SERVICES. YOU ASSUME TOTAL
              RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES. YOUR
              SOLE REMEDY AGAINST AtlasTech Solutions FOR DISSATISFACTION WITH
              THE SITE OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH
              CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN
              BETWEEN THE PARTIES.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Limitation of Liability</h2>
            <p className="mt-2">
              Except where prohibited by law, in no event will AtlasTech
              Solutions be liable to you for any indirect, consequential,
              exemplary, incidental or punitive damages, including lost profits,
              even if AtlasTech Solutions has been advised of the possibility of
              such damages.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Indemnity</h2>
            <p className="mt-2">
              You agree to indemnify and hold AtlasTech Solutions, its officers,
              directors, shareholders, predecessors, successors in interest,
              employees, agents, subsidiaries and affiliates, harmless from any
              demands, loss, liability, claims or expenses (including attorneys’
              fees), made against AtlasTech Solutions by any third party due to
              or arising out of or in connection with your use of the Site.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Violation of These Terms of Use
            </h2>
            <p className="mt-2">
              AtlasTech Solutions may disclose any information we have about you
              (including your identity) if we determine that such disclosure is
              necessary in connection with any investigation or complaint
              regarding your use of the Site, or to identify, contact or bring
              legal action against someone who may be causing injury to or
              interference with (either intentionally or unintentionally)
              AtlasTech Solutions’s rights or property, or the rights or
              property of visitors to or users of the Site, including AtlasTech
              Solutions’s customers. AtlasTech Solutions reserves the right at
              all times to disclose any information that AtlasTech Solutions
              deems necessary to comply with any applicable law, regulation,
              legal process or governmental request. AtlasTech Solutions also
              may disclose your information when AtlasTech Solutions determines
              that applicable law requires or permits such disclosure, including
              exchanging information with other companies and organizations for
              fraud protection purposes.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Governing Law; Dispute Resolution
            </h2>
            <p className="mt-2">
              You agree that all matters relating to your access to or use of
              the Site, including all disputes, will be governed by the laws of
              the Australian Capital Territory, Australia without regard to its
              conflicts of laws provisions. You agree to the personal
              jurisdiction by and venue in the state and federal courts in the
              Australian Capital Territory, Australia, and waive any objection
              to such jurisdiction or venue. No recovery may be sought or
              received for damages other than out-of-pocket expenses, except
              that the prevailing party will be entitled to costs and attorneys’
              fees. In the event of any controversy or dispute between AtlasTech
              Solutions and you arising out of or in connection with your use of
              the Site, the parties shall attempt, promptly and in good faith,
              to resolve any such dispute. If we are unable to resolve any such
              dispute within a reasonable time (not to exceed thirty (30) days),
              then either party may submit such controversy or dispute to
              mediation. If the dispute cannot be resolved through mediation,
              then the parties shall be free to pursue any right or remedy
              available to them under applicable law.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Feedback and Information</h2>
            <p className="mt-2">
              Any feedback you provide at this site shall be deemed to be
              non-confidential. AtlasTech Solutions shall be free to use such
              information on an unrestricted basis.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Disclaimer</h2>
            <p className="mt-2">
              Protech Rentals is a subsidiary trading under Australian
              Registered Company AtlasTech Solutions ABN: 38 668 691 441
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
