import { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { Link, useNavigate } from "react-router-dom";
import AtlasSolutionsLogo from "../assests/Atlas_Solutions.png";
import { ABOUT_US, CONTACT_US, HOME_PAGE, PRODUCT } from "../PageRoutes";

// Define the paths as constants
const navigation = [
  { name: "Services", to: PRODUCT },
  { name: "About Us", to: ABOUT_US },
];

export default function Header({ contactUs = true }) {
  // State to manage the mobile menu open/close
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    // Header section with a navigation bar
    <>
      <div className="w-full bg-gray-900 p-4 pb-32 lg:fixed lg:top-0 lg:z-50">
        <header className="absolute inset-x-0 top-0 z-50 w-full bg-gray-900">
          <div className="mx-auto max-w-7xl">
            <div className="px-6 pt-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
              <nav className="justify flex items-center" aria-label="Global">
                <Link to={HOME_PAGE} className="-m-1.5 p-1.5">
                  <span className="sr-only">AtlasTech</span>
                  <img
                    alt="Atlas Logo"
                    className="w-auto max-w-xs"
                    src={AtlasSolutionsLogo}
                  />
                </Link>
                <div className="hidden px-16 lg:flex lg:items-center lg:space-x-14">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className="text-sm font-semibold text-white"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                {contactUs && (
                  <div className="hidden lg:absolute lg:right-6 lg:flex">
                    <div className="items-center">
                      <Link
                        to={CONTACT_US}
                        className="text-sm font-semibold text-black"
                      >
                        <button className="rounded-lg bg-atlas-yellow p-2 text-black">
                          Contact Sales
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-white lg:hidden"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-black/10">
              <div className="mx-auto flex items-center justify-between">
                <Link to={HOME_PAGE} className="-m-1.5 p-1.5">
                  <span className="sr-only">Atlas Logo</span>
                  <img
                    alt="Atlas Logo"
                    className="mx-auto w-auto max-w-xs"
                    src={AtlasSolutionsLogo}
                  />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-white"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-white/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-100"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="py-6">
                    <Link
                      key={"Contact Sales"}
                      to={CONTACT_US}
                      className="w-max  text-sm font-semibold leading-6 text-black"
                    >
                      <button className="rounded-lg bg-atlas-yellow p-2 text-black">
                        Contact Sales
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      {contactUs && (
        <footer className="fixed bottom-0 z-40 w-full bg-black p-4 lg:hidden">
          <div className="flex justify-center">
            <button
              onClick={() => navigate(CONTACT_US)}
              className="w-full rounded-lg bg-atlas-yellow p-2 font-semibold text-black"
            >
              Contact Sales
            </button>
          </div>
        </footer>
      )}
    </>
  );
}
