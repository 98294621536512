import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { ABOUT_US, CONTACT_US, HOME_PAGE, PRODUCT } from "./PageRoutes";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import ProductPage from "./pages/ProductPage";
import Wrapper from "./Wrapper";
import ThankYou from "./pages/ThankYouPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SalesAndReturnsPolicy from "./pages/Legal/SalesAndReturnPolicy";
import RefundPolicy from "./pages/Legal/RefundPolicy";
import TermsOfUse from "./pages/Legal/TermsOfUse";

function App() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path={HOME_PAGE} element={<HomePage />} />
          <Route path={ABOUT_US} element={<AboutUsPage />} />
          <Route path={CONTACT_US} element={<ContactUsPage />} />
          <Route path={PRODUCT} element={<ProductPage />} />
          <Route path={"thank-you"} element={<ThankYou />} />
          <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"refund-policy"} element={<RefundPolicy />} />
          <Route path={"terms-of-use"} element={<TermsOfUse />} />

          <Route path="sales-policy" element={<SalesAndReturnsPolicy />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
