const stats = [
  { label: "Zero Upfront Cost", value: "Save Thousands" },
  { label: "Flexible Usage", value: "Return Any Time" },
  { label: "IT Hassles Minimized", value: "Setup in Hours" },
  { label: "Satisfaction Guaranteed", value: "100% Commitment" },
];

export default function Stats() {
  return (
    <div className="bg-gray-900 pb-24 pt-16 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <p className="text-base font-semibold leading-7 text-atlas-yellow">
            Simplify Your IT
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Empowering Your Business, Effortlessly
          </h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
            <div>
              <p>
                AtlasTech Solutions brings you the ease of technology with
                managed device leases tailored for your business needs. We're
                here to take the tech load off your shoulders so you can
                concentrate on what you do best—running your business.
              </p>
            </div>
            <div>
              <p>
                We're proud to be a homegrown Australian business, serving local
                companies with integrity and dedication. Our team is invested in
                your success, providing personalized support and solutions that
                make a real difference.
              </p>
            </div>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
            {stats.map((stat, statIdx) => (
              <div key={statIdx} className="border-l border-white/20 pl-6">
                <dt className="order-2 mt-2 text-base leading-7 text-gray-300">
                  {stat.label}
                </dt>
                <dd className="order-1 text-3xl font-semibold tracking-tight text-white">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
