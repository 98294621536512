import Footer from "../components/Footer";
import Header from "../components/Header";
import MDM from "../assests/mdm.jpg";
import CTA from "../components/CTA";
import {
  ShieldCheckIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  LifebuoyIcon,
  DevicePhoneMobileIcon,
  WifiIcon,
} from "@heroicons/react/20/solid";
import { CONTACT_US } from "../PageRoutes";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const features = [
  {
    name: "Hardware",
    points: [
      "Short-term leases with flexible terms.",
      "Option to buy out the devices.",
      "Latest models available, ensuring top performance.",
      "Devices tailored to your specific business requirements.",
    ],
    icon: DevicePhoneMobileIcon,
  },
  {
    name: "Connectivity",
    points: [
      "Managed IT services for hassle-free connectivity.",
      "Mobile device management included.",
      "Reliable support for continuous business operations.",
      "Seamless integration with your existing systems.",
    ],
    icon: WifiIcon,
  },
  {
    name: "Security",
    points: [
      "Comprehensive coverage for loss, damage, and theft.",
      "Advanced security protocols to protect your data.",
      "Immediate support in case of security breaches.",
      "Regular updates to ensure ongoing protection.",
    ],
    icon: ShieldCheckIcon,
  },
];

const incentives = [
  {
    name: "Managed IT Support",
    description:
      "Enjoy complete peace of mind with our full IT support for all rented devices, ensuring smooth, uninterrupted business operations.",
    icon: LifebuoyIcon,
  },
  {
    name: "Flexible Lease Plans",
    description:
      "Customize your lease plan to fit your business's unique needs, with scalable options designed for cost-effectiveness and flexibility.",
    icon: CalendarIcon,
  },
  {
    name: "Accidental Damage Coverage",
    description:
      "Rest assured with our comprehensive accidental damage coverage, safeguarding your devices against life's unexpected mishaps.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Buyout Option",
    description:
      "Maximize your investment with the option to purchase your rented phones at a reduced cost, offering the flexibility of ownership.",
    icon: CurrencyDollarIcon,
  },
];

const faqs = [
  {
    question: "How does the Managed Device Lease process work?",
    answer:
      "Select your devices, choose your lease terms, and we'll handle everything else. Our process is designed for simplicity and efficiency.",
  },
  {
    question: "What happens if a leased device gets damaged?",
    answer:
      "You're fully covered with our comprehensive damage protection. We'll quickly replace it, ensuring minimal disruption to your business.",
  },
];

export default function ProductPage() {
  return (
    <div className="bg-black">
      <Header />
      <div className="bg-gray-900">
        <div className="relative isolate overflow-hidden pt-14">
          <img
            src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Revolutionize Your Business Communication
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Discover how AtlasTech Solutions' short-term managed device
                leases can transform your business's approach to communication
                and sales.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to={CONTACT_US}
                  className="rounded-md bg-atlas-yellow px-10 py-3 text-lg font-semibold text-black shadow-sm hover:bg-atlas-yellow-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atlas-yellow-dark"
                >
                  Contact Sales
                </Link>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        {/* Features section */}
        <div className="bg-black py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-atlas-yellow">
                Empower Your Business
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Managed Device Leases Tailored for Efficiency
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                AtlasTech Solutions brings you a comprehensive solution for your
                business technology needs. Our Managed Device Leases offer the
                latest in hardware, seamless connectivity, and robust security,
                ensuring your business stays ahead in today's digital landscape.
                Streamline your operations and safeguard your assets with
                technology that evolves with you.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {features.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                      <feature.icon
                        className="h-5 w-5 flex-none text-atlas-yellow"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                      <ul className="list-disc pl-5">
                        {feature.points.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-gray-900 text-white">
        <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
          <div className="relative lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
            <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
              <img
                className="absolute inset-0 h-full w-full rounded-lg bg-gray-50 object-cover shadow-lg"
                src={MDM}
                alt="Modern Business Technology"
              />
              <div className="absolute inset-0 h-full w-full border-8 border-black blur-md" />
            </div>
          </div>
          <div className="px-6 lg:contents">
            <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
                Empower Your Business with Smart Technology Management
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-300">
                AtlasTech Solutions brings you the power of Mobile Device
                Management (MDM), streamlining your operations and securing your
                business assets. Leverage our managed device leases to gain
                control and efficiency in your tech infrastructure.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-300">
                <li className="flex gap-x-3">
                  <DevicePhoneMobileIcon
                    className="mt-1 h-5 w-5 flex-none text-atlas-yellow"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-100">
                      Advanced Device Management.
                    </strong>{" "}
                    Gain centralized control over your devices, ensuring they
                    are always up to date, secure, and functioning optimally.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <WifiIcon
                    className="mt-1 h-5 w-5 flex-none text-atlas-yellow"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-100">
                      Seamless Connectivity.
                    </strong>{" "}
                    Experience uninterrupted connectivity with expertly managed
                    services, keeping your team connected wherever they are.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ShieldCheckIcon
                    className="mt-1 h-5 w-5 flex-none text-atlas-yellow"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-100">
                      Robust Security Measures.
                    </strong>{" "}
                    Protect sensitive business data with advanced security
                    protocols, minimizing risks and safeguarding your
                    operations.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
                Embrace the full potential of MDM with AtlasTech Solutions. Our
                managed leases offer a cost-effective, scalable way to upgrade
                your business technology, making it an asset, not a challenge.
              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-100">
                Transform Your Business with AtlasTech
              </h2>
              <p className="mt-6">
                Reach out today to learn more about how our MDM solutions can
                enhance your business's productivity and security. Let's make
                your technology work smarter for you.
              </p>
              <div className="mt-10 flex items-center justify-center">
                <Link
                  to={CONTACT_US}
                  className={`w-full rounded-md bg-atlas-yellow px-3.5 py-2.5 text-center text-sm font-semibold text-black
                 shadow-sm hover:bg-atlas-yellow-dark focus-visible:outline focus-visible:outline-2
                 focus-visible:outline-offset-2 focus-visible:outline-white`}
                >
                  Contact Sales
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Incentives */}
      <div className="bg-black">
        <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
          <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
            {incentives.map((incentive) => (
              <div key={incentive.name} className="text-white">
                <incentive.icon
                  className="h-24 w-auto text-atlas-yellow"
                  aria-hidden="true"
                />
                <h3 className="mt-6 text-sm font-medium">{incentive.name}</h3>
                <p className="mt-2 text-sm">{incentive.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="mx-auto max-w-7xl sm:px-6 sm:py-16 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-16 text-center shadow-2xl sm:rounded-3xl sm:px-16">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-20">
              <div className="mx-auto max-w-4xl divide-y divide-white/10">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
                  Frequently Asked Questions
                </h2>
                <dl className="space-y-6 divide-y divide-white/10">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                              <span className="text-base font-semibold leading-7">
                                {faq.question}
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusSmallIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-300">
                              {faq.answer}
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6"></div>
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <CTA
        title1="Ready to Transform Your Business Communication?"
        description="Join the multitude of businesses enhancing their team's efficiency and sales potential with our managed device  leases. Reach out now to learn more."
        link="/contact-us"
        linkText="Contact Us"
        bgColour="bg-black"
      />
      <Footer />
    </div>
  );
}
