import { PRODUCT, CONTACT_US } from "../PageRoutes";
import Header from "../components/Header";
import CTA from "../components/CTA";
import CTABubble from "../components/CTABubble";
import Footer from "../components/Footer";
import { useState } from "react";

import {
  AcademicCapIcon,
  CheckCircleIcon,
  HandRaisedIcon,
  RocketLaunchIcon,
  SparklesIcon,
  SunIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import KyleHughes from "../assests/Kyle Hughes.jpg";
import BlakeBloor from "../assests/Blake Bloor.jpg";
import DylanRobertson from "../assests/Dylan Robertson.jpg";

const stats = [
  { label: "Zero Upfront Cost", value: "Save Thousands" },
  { label: "Flexible Usage", value: "Return Any Time" },
  { label: "IT Hassles Minimized", value: "Setup in Hours" },
  { label: "Satisfaction Guaranteed", value: "100% Commitment" },
];

// Company values updated to mirror AtlasTech Solutions' ethos
const values = [
  {
    name: "Empowering Businesses",
    description:
      "We believe in providing solutions that empower small and medium-sized businesses to thrive, even without a tech department.",
    icon: RocketLaunchIcon,
  },
  {
    name: "Ease and Accessibility",
    description:
      "Our services are designed for ease of use, ensuring technology is accessible to everyone, regardless of their tech-savviness.",
    icon: HandRaisedIcon,
  },
  {
    name: "Team-Oriented Approach",
    description:
      "We value collaboration and a supportive approach, both within our team and in our interactions with clients.",
    icon: UserGroupIcon,
  },
  {
    name: "Continual Growth",
    description:
      "Learning and evolving is at the core of what we do - we are always enhancing our services to better meet your needs.",
    icon: AcademicCapIcon,
  },
  {
    name: "Sharing Knowledge",
    description:
      "We believe in transparency and sharing our expertise to help businesses make informed decisions.",
    icon: SparklesIcon,
  },
  {
    name: "Value Work-Life Balance",
    description:
      "Understanding the importance of downtime, we strive to make business operations smoother so you can enjoy life's moments.",
    icon: SunIcon,
  },
];

// The team section updated to reflect actual team members
const team = [
  {
    name: "Kyle Hughes",
    role: "Managing Director",
    imageUrl: KyleHughes,
    location: "Canberra, NSW",
  },
  {
    name: "Blake Bloor",
    role: "Operating Officer",
    imageUrl: BlakeBloor,
    location: "Canberra, NSW",
  },
  {
    name: "Dylan Robertson",
    role: "Technology Officer",
    imageUrl: DylanRobertson,
    location: "Canberra, NSW",
  },
];

// Updated benefits to reflect the focus on team well-being and work culture
const benefits = [
  "Short-term, flexible managed device leases tailored to your business needs",
  "Complete IT management, freeing you from tech hassles",
  "No upfront costs, with a portion of payments reducing the buyout price",
  "Freedom to cancel anytime, offering you peace of mind",
  "Unlimited accidental damage coverage for worry-free usage",
  "Expert support dedicated to keeping your team connected and productive",
];

function AboutUsPage() {
  return (
    <div>
      <Header />
      <div className=" flex flex-col items-center justify-center bg-black pb-16">
        <main className="relative isolate">
          {/* Background */}
          <div
            className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
              style={{
                clipPath:
                  "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
              }}
            />
          </div>

          {/* Header section */}
          <div className="px-6 pt-14 lg:px-8">
            <div className="mx-auto max-w-2xl pt-24 text-center sm:pt-40">
              <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Empowering Your Business Technology
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                At AtlasTech Solutions, we're dedicated to removing the
                technological hurdles from your business path. We understand the
                challenges faced by small and medium-sized businesses and offer
                tailor-made tech solutions to streamline your operations.
              </p>
            </div>
          </div>

          {/* Content section */}
          <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
                <div>
                  <p>
                    Our managed device lease service is designed with your
                    business in mind. Say goodbye to the complexities of
                    managing tech and focus on what you do best - growing your
                    business. With no upfront costs and a flexible lease plan,
                    we keep your team connected without the financial burden.
                  </p>
                  <p className="mt-8">
                    We handle all the IT aspects of your phones. From setup to
                    support, our expert team is there to ensure a seamless
                    experience, so you never have to worry about tech issues
                    interfering with your business deadlines.
                  </p>
                </div>
                <div>
                  <p>
                    With AtlasTech Solutions, you're not just renting a phone;
                    you're securing a partner in your business growth. Our plans
                    are budget-friendly, with a portion of your payments going
                    towards the buyout option, giving you the flexibility of
                    ownership without the immediate expense.
                  </p>
                  <p className="mt-8">
                    Plus, our unlimited accidental damage coverage and warranty
                    mean that you can operate with peace of mind, knowing that
                    we've got you covered in any eventuality.
                  </p>
                </div>
              </div>

              <dl className="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
                {stats.map((stat, statIdx) => (
                  <div
                    key={statIdx}
                    className="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6"
                  >
                    <dt className="text-base leading-7 text-gray-300">
                      {stat.label}
                    </dt>
                    <dd className="text-3xl font-semibold tracking-tight text-white">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Image section */}
          <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
            <img
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2894&q=80"
              alt=""
              className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
            />
          </div>

          {/* Values section */}
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Our Core Values
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                At AtlasTech Solutions, our values are the cornerstone of our
                business. They guide our decisions, our service, and our
                commitment to you.
              </p>
            </div>
            <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
              {values.map((value) => (
                <div key={value.name} className="relative pl-9">
                  <dt className="inline font-semibold text-white">
                    <value.icon
                      className="absolute left-1 top-1 h-5 w-5 text-atlas-yellow"
                      aria-hidden="true"
                    />
                    {value.name}
                  </dt>{" "}
                  <dd className="inline">{value.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          {/* Team section */}
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Meet Our Team
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Our dedicated team at AtlasTech Solutions is the driving force
                behind our innovative solutions. Meet the people who make it all
                happen.
              </p>
            </div>
            <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-3">
              {team.map((person) => (
                <li key={person.name}>
                  <img
                    className="aspect-[14/13] w-full rounded-2xl object-cover"
                    src={person.imageUrl}
                    alt=""
                  />
                  <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">
                    {person.name}
                  </h3>
                  <p className="text-base leading-7 text-gray-300">
                    {person.role}
                  </p>
                  <p className="text-sm leading-6 text-gray-500">
                    {person.location}
                  </p>
                </li>
              ))}
            </ul>
          </div>

          {/* CTA section */}
          <div className="relative isolate -z-10 mt-32 sm:mt-40">
            <div className="mx-auto mb-10 max-w-7xl sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                <img
                  className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                  src="https://images.unsplash.com/photo-1512428559087-560fa5ceab42?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Efficient Business Communication"
                />
                <div className="w-full flex-auto">
                  <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    Elevate Your Business Communication Today
                  </h2>
                  <p className="mt-6 text-lg leading-8 text-gray-300">
                    At AtlasTech Solutions, we understand the importance of
                    seamless communication in business. Our managed device
                    leases are designed to boost your team's efficiency and
                    sales potential, without the burden of tech management.
                    Explore the benefits we offer:
                  </p>
                  <ul
                    role="list"
                    className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
                  >
                    {benefits.map((benefit) => (
                      <li key={benefit} className="flex gap-x-3">
                        <CheckCircleIcon
                          className="h-7 w-5 flex-none text-atlas-yellow"
                          aria-hidden="true"
                        />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                  <div className="mt-10">
                    <Link
                      to={CONTACT_US}
                      className="block w-full rounded-md bg-atlas-yellow px-10 py-3 text-center text-lg font-semibold text-black shadow-sm hover:bg-atlas-yellow-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-atlas-yellow-dark"
                    >
                      Contact Sales
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                style={{
                  clipPath:
                    "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                }}
              />
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUsPage;
