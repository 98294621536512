import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB9-nzGqp5foiwzEf7G31iyjhMdN-u9qDY",
  authDomain: "atlastechsolutions-1b823.firebaseapp.com",
  projectId: "atlastechsolutions-1b823",
  storageBucket: "atlastechsolutions-1b823.appspot.com",
  messagingSenderId: "1020536379727",
  appId: "1:1020536379727:web:1695af9876b3f48bea4d3a",
  measurementId: "G-4JCQSHQDJ8",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export { db };
