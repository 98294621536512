import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const RefundPolicy = () => {
  return (
    <>
      <Header />
      <div className="bg-gray-100 px-4 pb-8 pt-32 md:pt-48">
        <div className="mx-auto max-w-4xl rounded-lg bg-white p-6 shadow">
          <p className="text-xl font-bold">Last updated: [11/11/2023]</p>
          <h1 className="mt-4 text-3xl font-bold">Refund Policy</h1>

          <p className="mt-6">
            Our goods and services come with guarantees that cannot be excluded
            under the Australian Consumer Law, and our refund policy is subject
            to that Law/Act.
          </p>

          <p className="mt-2">
            The customer must also comply with the Terms and Conditions outlined
            in AtlasTech’s Hire Agreement and/or Account Application Form.
          </p>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Cancellation of Hire/Subscription
            </h2>
            <p className="mt-2">
              As a customer, you may only cancel your hire or subscription
              online or over the phone (between the hours of 0900 and 1700 AEST)
              at any time prior to the hire commencing. Where delivery has been
              requested and cancellation is inside 2 hours of the start
              date/time, a 'futile delivery' charge will apply when the order
              has been processed. The amount charged for 'futile delivery' will
              not exceed the quoted amount for delivery. AtlasTech also reserves
              the right to on-charge any other legitimate costs incurred
              following a cancellation.
            </p>

            <p className="mt-2">
              Upon cancellation, AtlasTech will refund any deposit taken for
              orders not yet received by the customer.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Cancellation of Subscription (Consumer)
            </h2>
            <p className="mt-2">
              As a customer, you may only cancel your subscription online or
              over the phone (between the hours of 0900 and 1700 AEST) at any
              time. The device must be returned to our office address (as listed
              on the Hire Agreement) within 24 hours following the cancellation.
              Failure to comply with the above will be a direct breach of the
              Hire Agreement. AtlasTech reserves the right to enforce
              collections/recovery as stated in the Rental Agreement and impose
              fees for any other legitimate costs associated with the breach.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Faulty Equipment</h2>
            <p className="mt-2">
              It is AtlasTech’s responsibility to provide equipment free from
              fault and in good working order. If any item is received in a
              faulty condition or not in good working order, AtlasTech will
              either repair or replace the item, or refund any payment taken.
            </p>

            <p className="mt-2">
              When an item has been delivered, repair or replacement of the item
              onsite will be entirely at AtlasTech’s cost where the Equipment is
              hired by the customer to be located within 20 kilometres from the
              hiring branch. Where Equipment is hired by the customer to be
              located more than 20 kilometres from the hiring branch, repair or
              replacement of the item onsite will be at the cost of the
              customer.
            </p>

            <p className="mt-2">
              (Please note: for remote hire, the customer is generally
              responsible, at its cost, for daily maintenance and care of
              equipment in its possession. The customer will generally pay all
              charges relating to delivery, servicing, and repair of equipment
              and for any other attendance by AtlasTech. Charges may include
              parts, consumables, travel to and from, labor costs, call-out
              fees, and direct travel costs.)
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Post-Hire Sales Items (ABN Holders)
            </h2>
            <p className="mt-2">
              AtlasTech will handle refunds in accordance with our Sales and
              Returns Policy.
            </p>
          </section>

          <section className="mt-6">
            <h2 className="text-xl font-semibold">Disclaimer</h2>
            <p className="mt-2">
              Protech Rentals is a subsidiary trading under Australian
              Registered Company AtlasTech Solutions ABN: 38 668 691 441.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
