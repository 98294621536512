import Header from "../components/Header";
import Footer from "../components/Footer";

function ThankYou() {
  return (
    <div className="flex h-full min-h-screen w-full flex-col bg-gray-900">
      <Header contactUs={false} />
      <div className="lg:p-16"></div>
      <div className="flex flex-grow flex-col items-center justify-center bg-gradient-to-tr from-atlas-yellow-dark to-yellow-200">
        <div className="flex flex-col items-center justify-center rounded-2xl bg-white bg-opacity-50 p-12 shadow-lg md:my-64 md:p-32">
          <h1 className="mb-8 text-3xl font-bold text-black md:mb-16 md:text-6xl">
            Thanks for reaching out!
          </h1>
          <p className="text-lg text-black md:text-4xl">
            We will get back to you soon!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ThankYou;
