import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const SalesAndReturnsPolicy = () => {
  return (
    <>
      <Header />
      <div className="bg-gray-100 px-4 pb-8 pt-32 md:pt-48">
        <div className="mx-auto max-w-4xl rounded-lg bg-white p-6 shadow">
          <p className="text-xl font-bold">Last updated: [11/11/2023]</p>
          <h1 className="mt-4 text-3xl font-bold">Sales and Returns Policy</h1>

          {/* Standard Return Policy */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">Standard Return Policy</h2>
            <p className="mt-2">
              We fundamentally believe you will be thrilled with the products
              you purchase from AtlasTech. That’s because we go out of our way
              to ensure that we source the best devices on the market. We
              understand, however, that sometimes a product may not be what you
              expected it to be. In that event, we invite you to review the
              following terms related to returning a product.
            </p>
            <p className="mt-2">
              Please note there may be limitations on your right to return and
              obtain a refund for products, however, these limits will always be
              subject to your statutory rights.
            </p>
            <p className="mt-2">
              For any undamaged product, simply return it with its included
              accessories and packaging along with the original receipt and
              original packaging within 14 days of the date you receive the
              product, and we’ll exchange it or offer a refund based upon the
              original payment method. However, please note that we will only
              remit refunds to an Australian bank account where the name of the
              bank account holder matches the payor’s name/information. Please
              also note that we only remit refunds domestically and cannot remit
              refunds outside of Australia. In addition, please note the
              following:
            </p>
            <ul className="ml-6 list-disc">
              <li>
                Products can be returned to the branch in which they were
                originally purchased.
              </li>
              <li>
                Except where required under the Law, the following products are
                not eligible for return: MDM services provided by AtlasTech and
                Software subscriptions/licensing provided by AtlasTech.
              </li>
              <li>
                In the case the items returned were subject to a discount,
                AtlasTech reserves the right to revoke any discounts previously
                granted for the purchase of a replacement device.
              </li>
              <li>
                AtlasTech provides security features to enable you to protect
                your product in case of loss or theft. If these features have
                been activated and cannot be disabled by the person in
                possession of the phone, AtlasTech may refuse the return or
                exchange.
              </li>
            </ul>
          </section>

          {/* Additional AtlasTech Return Terms */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Additional AtlasTech Return Terms
            </h2>
            <p className="mt-2">
              Making unauthorized modifications to the software on an iPhone
              violates the iPhone software license agreement. Should you be
              unable to use your iPhone due to an unauthorized software
              modification, its repair or replacement will not be covered under
              the warranty. AtlasTech reserves the right to refuse the return of
              tampered devices.
            </p>
          </section>

          {/* Pricing and Price Reductions/Corrections */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Pricing and Price Reductions/Corrections
            </h2>
            <p className="mt-2">
              AtlasTech reserves the right to change prices for products
              displayed at/on the AtlasTech website at any time, and to correct
              pricing errors that may inadvertently occur.
            </p>
          </section>

          {/* Order Acceptance */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">Order Acceptance</h2>
            <p className="mt-2">
              AtlasTech may, in its sole discretion, refuse or cancel any order
              and limit order quantity. AtlasTech reserves the right at any time
              after receiving your order to accept or decline your order for any
              reason. If AtlasTech cancels an order after you have already been
              billed, AtlasTech will refund the billed amount.
            </p>
          </section>

          {/* Shipping & Delivery */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">Shipping & Delivery</h2>
            <p className="mt-2">
              Shipping and delivery charges will be at the expense of the
              customer, AtlasTech reserves the right to refuse shipping or
              delivery and require in-store pickup at their discretion.
            </p>
          </section>

          {/* In-Store Pickup and Return */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              In-Store Pickup and Return
            </h2>
            <p className="mt-2">
              AtlasTech offers in-store pickup as default. Certain products and
              payment methods, however, may not qualify for in-store pickup.
              Only you or a person designated by you may pick up the devices(s)
              purchased.
            </p>
          </section>

          {/* Product Availability and Limitations */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">
              Product Availability and Limitations
            </h2>
            <p className="mt-2">
              Given the popularity and/or supply constraints of some of our
              products, AtlasTech may have to limit the number of products
              available for purchase. AtlasTech reserves the right to change
              quantities available for purchase at any time, even after you
              place an order. Furthermore, there may be occasions when AtlasTech
              confirms your order but subsequently learns that it cannot supply
              the ordered product. In the event we cannot supply a product you
              ordered, AtlasTech will cancel the order and refund your purchase
              price in full.
            </p>
          </section>

          {/* Data Protection */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">Data Protection</h2>
            <p className="mt-2">
              By placing your Order, you agree that we may store, process, and
              use data collected from your Order Form as set out in our Privacy
              Policy.
            </p>
          </section>

          {/* Disclaimer */}
          <section className="mt-6">
            <h2 className="text-xl font-semibold">Disclaimer</h2>
            <p className="mt-2">
              Protech Rentals is a subsidiary trading under Australian
              Registered Company AtlasTech Solutions ABN: 38 668 691 441
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SalesAndReturnsPolicy;
