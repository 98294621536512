import { Link } from "react-router-dom";

export default function HeroComponent({
  heading,
  subheading,
  primaryActionText,
  primaryLink,
  secondaryActionText,
  secondaryLink,
}) {
  return (
    <div className="bg-black text-white">
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-black lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>
            <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-2xl">
                <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
                  {heading}
                </h1>
                <p className="mt-6 text-lg leading-8">{subheading}</p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to={primaryLink}
                    className="rounded-md bg-atlas-yellow px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {primaryActionText}
                  </Link>
                  {secondaryActionText && (
                    <Link
                      to={secondaryLink}
                      className="text-sm font-semibold leading-6"
                    >
                      {secondaryActionText} <span aria-hidden="true">→</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
