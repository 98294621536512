import {
  CurrencyDollarIcon,
  PhoneIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/solid";
import { PRODUCT } from "../../PageRoutes";
import { Link } from "react-router-dom";

const features = [
  {
    name: "Custom Device Configurations",
    description:
      "Our leased devices come with pre-configured settings and essential apps to ensure your team can start working immediately, without any setup hassles.",
    icon: PhoneIcon,
    to: PRODUCT,
  },
  {
    name: "Flexible Leasing Options",
    description:
      "Get the latest technology for your team with flexible and budget-friendly lease terms. Our plans are designed to adapt to your evolving business needs.",
    icon: CurrencyDollarIcon,
    to: PRODUCT,
  },
  {
    name: "Comprehensive Protection",
    description:
      "We provide complete peace of mind with extensive coverage for every leased device, protecting your business from unforeseen damages and disruptions.",
    icon: ShieldCheckIcon,
    to: PRODUCT,
  },
];

export default function TripleFeature() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-atlas-yellow">
            Enhance Your Business Operations
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Customized Managed Device Leases
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Explore how AtlasTech Solutions' Managed Device Leases can be
            tailored to meet your business's unique technological needs.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                  <feature.icon
                    className="h-5 w-5 flex-none text-atlas-yellow"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <Link
                      to={feature.to}
                      className="text-sm font-semibold leading-6 text-atlas-yellow"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </Link>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
