import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import HeroComponent from "../components/HeroComponent";
import CTABubble from "../components/CTABubble";
import ContentSection from "../components/ContentSection";
import Testimonials from "../components/Testimonials";
import Stats from "../components/Stats";
import TripleFeature from "../components/FeatureSections/TripleFeature";
import { CONTACT_US, PRODUCT } from "../PageRoutes";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "How does the Managed Device Lease process work?",
    answer:
      "Select your devices, choose your lease terms, and we'll handle everything else. Our process is designed for simplicity and efficiency.",
  },
  {
    question: "What happens if a leased device gets damaged?",
    answer:
      "You're fully covered with our comprehensive damage protection. We'll quickly replace it, ensuring minimal disruption to your business.",
  },
  {
    question: "How does the procurement process work for my business?",
    answer:
      "To become a customer, you will be required to lodge an account application form, containing the terms and conditions of your account and device leasing. Once your account has been approved, all it takes is a simple email and you could have your device by the end of the day.",
  },
  {
    question: "How Flexible are AtlasTech's Managed Device Leases?",
    answer:
      "We would say we offer the most flexible device leasing on the market! Providing no lock ins, no fixed terms and the option to swap, return or buy out your device at any time!",
  },
  {
    question:
      "Do you offer Mobile Device Management (MDM) for devices you provide?",
    answer:
      "We sure do! We have partnered with SolutionTech to offer MDM and MAM as an optional add-on to our services.",
  },
  {
    question: "Do you offer mobile data plans for leased devices?",
    answer:
      "Unfortunately, at this time we do not. Although we are actively working behind the scenes with an existing telco provider to develop an offering to provide alongside our service.",
  },
  {
    question: "Does the device come set up, or in factory settings?",
    answer:
      "It's your call. You can have the devices sent to you in a factory setting for you to setup as you require. Or, we can assist in setting the device up with you to ensure you hit the ground running.",
  },
  {
    question: "Are the devices you provide new or second hand?",
    answer:
      "We lean towards offering refurbished devices as this is a further cost save we can pass onto you. But we are more than happy to provide new devices upon request.",
  },
];

const HomePage = () => {
  return (
    <div className="flex h-screen flex-col bg-black">
      <Header />
      <HeroComponent
        heading="Seamless Device Solutions for Your Business"
        subheading="Elevate your team's productivity with our Managed Device Leases. Ideal for businesses without a dedicated tech team."
        primaryActionText="Explore Options"
        primaryLink={PRODUCT}
        secondaryActionText="Get in Touch"
        secondaryLink={CONTACT_US}
      />
      <TripleFeature />
      <ContentSection />
      <Stats />
      <Testimonials />

      <div className="bg-black">
        <div className="mx-auto mb-24 max-w-7xl sm:px-6 sm:py-16 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-16 text-center shadow-2xl sm:rounded-3xl sm:px-16">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-20">
              <div className="mx-auto max-w-4xl divide-y divide-white/10">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
                  Frequently Asked Questions
                </h2>
                <dl className="space-y-6 divide-y divide-white/10">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                              <span className="text-base font-semibold leading-7">
                                {faq.question}
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusSmallIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-300">
                              {faq.answer}
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={PRODUCT}
                className="rounded-md bg-atlas-yellow px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100
                 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Discover More
              </Link>
            </div>
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
