import { Link } from "react-router-dom";

export default function CTA({
  title1,
  title2,
  description,
  link,
  linkText,
  bgColour,
}) {
  return (
    <div className={bgColour ?? "bg-black"}>
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {title1}
            <br />
            {title2}
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
            {description}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to={link}
              className={
                `w-full rounded-md bg-atlas-yellow px-3.5 py-2.5 text-center text-sm font-semibold shadow-sm hover:bg-atlas-yellow-dark
                 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                 focus-visible:outline-white` +
                (bgColour === `bg-black` ? ` text-black` : ` text-black`)
              }
            >
              {linkText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
